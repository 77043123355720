import {
  Button,
  Divider,
  Link,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as API from "src/api";
import CustomLink from "../custom-link";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "src/hooks/use-responsive";
import { generateHex } from "src/util";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
interface Props extends StackProps {
  data: any;
}
const buildDataFromOff = (data: any) => {
  const { from, to, amount, ts, txid, fee, confirmations, balance_after } =
    data;
  const vin = [
    {
      scriptSig: {
        asm: `${generateHex(142)}[ALL|FORKID] ${generateHex(66)}`,
      },
      addr: from,
      value: balance_after + amount + fee,
    },
  ];

  const vout = [
    {
      value: balance_after,
      scriptPubKey: {
        asm: `OP_DUP OP_HASH160 ${generateHex(40)} OP_EQUALVERIFY OP_CHECKSIG`,
        addresses: [from],
        type: "pubkeyhash",
      },
    },
    {
      value: amount,
      scriptPubKey: {
        asm: `OP_DUP OP_HASH160 ${generateHex(40)} OP_EQUALVERIFY OP_CHECKSIG`,
        addresses: [to],
        type: "pubkeyhash",
      },
    },
  ];
  return {
    txid: data.txid,
    vin: vin,
    vout: vout,
    time: ts,
    size: 226,
    fees: fee,
    confirmations: confirmations,
    valueOut: balance_after + amount,
  };
};
const TransactionDetail = ({ data, sx }: Props) => {
  const isSmall = useResponsive("down", "md");

  const navigate = useNavigate();
  const [isPlus, setIsPlus] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const [isMore, setIsMore] = useState(false);

  useEffect(() => {
    // off chain
    if (data?._id) {
      setTransaction(buildDataFromOff(data));
    } else {
      setTransaction(data);
    }
  }, [data]);

  const vout = useMemo(() => {
    if (transaction?.vout) {
      // console.log("transaction.vout", transaction?.vout.length);
      if (!isMore && transaction.vout?.length > 5) {
        return transaction.vout.slice(0, 5);
      }
      return transaction.vout;
    }
    return [];
  }, [transaction, isMore]);

  const onExpand = () => {
    setIsMore(!isMore);
  };

  return (
    <Stack
      bgcolor="#F4F4F4"
      mt={3}
      borderRadius={1.5}
      border="1px solid #EEEEEE"
      sx={{
        ...sx,
      }}
    >
      <Stack direction="row" p={2} alignItems="center">
        {isPlus ? (
          <RemoveCircleIcon
            onClick={() => {
              setIsPlus(!isPlus);
            }}
            sx={{
              color: "#9B9B9B",
              fontSize: "14px",
            }}
          />
        ) : (
          <AddCircleIcon
            onClick={() => {
              setIsPlus(!isPlus);
            }}
            sx={{
              color: "#9B9B9B",
              fontSize: "14px",
            }}
          />
        )}
        <CustomLink
          ml={1}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => navigate(`/insight/tx/${transaction?.txid}`)}
        >
          {transaction?.txid}
        </CustomLink>
      </Stack>
      <Divider
        sx={{
          borderColor: "#EAEAEA",
        }}
      />
      <Stack
        direction={isSmall ? "column" : "row"}
        p={2}
        sx={{
          "& div": {
            minWidth: 0,
          },
          "& p": {
            flexBasis: 0,
          },
        }}
      >
        {transaction?.vin?.[0]?.coinbase ? (
          <Typography
            fontFamily="Ubuntu, sans-serif"
            fontSize={isSmall ? "11px" : "14px"}
            lineHeight={1.43}
            flexGrow={1}
            mb="auto"
            ml={2}
          >
            No Inputs (Newly Generated Coins)
          </Typography>
        ) : (
          <Stack
            mb={isSmall ? "unset" : "auto"}
            flex={isSmall ? "unset" : "1 1 0px"}
          >
            <Stack
              bgcolor="#FFFFFF"
              borderRadius={1.5}
              border="1px solid #DDDDDD"
              p={1}
              direction="row"
              alignItems="center"
              mb={2}
            >
              <CustomLink ml={isSmall ? 0 : 1} fontWeight={700}>
                <NavigateNextIcon />
              </CustomLink>
              <CustomLink
                ml={isSmall ? 0 : 1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                onClick={() =>
                  navigate(`/insight/address/${transaction?.vin?.[0].addr}`)
                }
              >
                {transaction?.vin?.[0].addr}
              </CustomLink>
              <Typography
                ml="auto"
                fontSize={isSmall ? "11px" : "14px"}
                lineHeight={1.43}
              >
                {transaction?.vin?.[0].value}&nbsp;BTG
              </Typography>
            </Stack>
            {isPlus &&
              transaction?.vin?.map((v: any, i: number) => (
                <Stack key={i}>
                  <Typography
                    component="span"
                    fontFamily="Ubuntu, sans-serif"
                    fontSize={isSmall ? "9px" : "12px"}
                    lineHeight={1.43}
                    fontWeight={700}
                    mb={1}
                    ml={1}
                  >
                    Confirmations:
                  </Typography>
                  <Typography
                    component="span"
                    fontFamily="Ubuntu, sans-serif"
                    fontSize={isSmall ? "9px" : "12px"}
                    lineHeight={1.43}
                    fontWeight={700}
                    mb={1}
                    ml={1}
                  >
                    scriptSig
                  </Typography>
                  <Stack
                    direction="column"
                    bgcolor="#FFFFFF"
                    borderRadius={1.5}
                    border="1px solid #DDDDDD"
                    p={1}
                    alignItems="center"
                    mb={2}
                  >
                    <Typography
                      ml={2}
                      mb={1}
                      component="span"
                      fontFamily="Ubuntu, sans-serif"
                      fontSize={isSmall ? "9px" : "12px"}
                      color="#999"
                      lineHeight={1.43}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      width="100%"
                    >
                      {v?.scriptSig?.asm.split(" ")[0]}
                    </Typography>
                    <Typography
                      ml={2}
                      mb={1}
                      component="span"
                      fontFamily="Ubuntu, sans-serif"
                      fontSize={isSmall ? "9px" : "12px"}
                      color="#999"
                      lineHeight={1.43}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      width="100%"
                    >
                      {v?.scriptSig?.asm.split(" ")[1]}
                    </Typography>

                    <Typography
                      ml="auto"
                      fontFamily="Ubuntu, sans-serif"
                      fontSize={isSmall ? "11px" : "14px"}
                      lineHeight={1.43}
                    >
                      {v?.value ?? 0}&nbsp;BTG
                    </Typography>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        )}
        {!isSmall && (
          <Stack alignItems="center" width="60px" flex="0 0 20px">
            <NavigateNextIcon
              sx={{
                color: "#999999",
                fontSize: "36px",
                fontStyle: "bold",
              }}
            />
          </Stack>
        )}
        <Stack direction="column" flex={isSmall ? "unset" : "1 1 0px"}>
          {vout.map((v: any, i: number) => (
            <Stack key={i}>
              <Stack
                bgcolor="#FFFFFF"
                borderRadius={1.5}
                border="1px solid #DDDDDD"
                p={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                {v.scriptPubKey?.addresses ? (
                  <>
                    <CustomLink
                      ml={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      onClick={() =>
                        navigate(
                          `/insight/address/${v.scriptPubKey?.addresses?.[0]}`
                        )
                      }
                    >
                      {v.scriptPubKey?.addresses?.[0]}
                    </CustomLink>
                    <Typography
                      ml="auto"
                      fontSize={isSmall ? "11px" : "14px"}
                      lineHeight={1.43}
                    >
                      {parseFloat(v.value)}&nbsp;BTG
                    </Typography>
                    <CustomLink ml={1} fontWeight={700}>
                      <NavigateNextIcon />
                    </CustomLink>
                  </>
                ) : (
                  <>
                    <Typography
                      fontFamily="Ubuntu, sans-serif"
                      ml={1}
                      fontSize={isSmall ? "11px" : "14px"}
                      lineHeight={1.43}
                      width={500}
                    >
                      Unparsed&nbsp;address&nbsp;[0]
                    </Typography>
                    <Typography
                      fontFamily="Ubuntu, sans-serif"
                      ml="auto"
                      fontSize={isSmall ? "11px" : "14px"}
                      lineHeight={1.43}
                    >
                      0&nbsp;BTG
                    </Typography>
                    <CustomLink ml={1} fontWeight={700}>
                      <NavigateNextIcon />
                    </CustomLink>
                  </>
                )}
              </Stack>
              {isPlus && (
                <>
                  <Typography ml={2} mb={1}>
                    <Typography
                      component="span"
                      fontFamily="Ubuntu, sans-serif"
                      fontSize={isSmall ? "9px" : "12px"}
                      lineHeight={1.43}
                      fontWeight={700}
                    >
                      Type
                    </Typography>
                    <Typography
                      component="span"
                      fontFamily="Ubuntu, sans-serif"
                      fontSize={isSmall ? "9px" : "12px"}
                      color="#999"
                      lineHeight={1.43}
                    >
                      &nbsp;{v?.scriptPubKey?.type ?? ""}
                    </Typography>
                  </Typography>
                  <Typography
                    ml={2}
                    mb={1}
                    fontFamily="Ubuntu, sans-serif"
                    fontSize={isSmall ? "9px" : "12px"}
                    lineHeight={1.43}
                    fontWeight={700}
                  >
                    scriptPubKey
                  </Typography>
                  <Typography
                    ml={4}
                    mb={1}
                    component="span"
                    fontFamily="Ubuntu, sans-serif"
                    fontSize={isSmall ? "9px" : "12px"}
                    color="#999"
                    lineHeight={1.43}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    &nbsp;{v?.scriptPubKey?.asm ?? ""}
                  </Typography>
                </>
              )}
            </Stack>
          ))}
          {transaction?.vout?.length > 5 && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ maxWidth: 200 }}
              startIcon={isMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={onExpand}
            >
              {isMore ? "Show less" : "Show More"}
            </Button>
          )}
        </Stack>
      </Stack>
      <Divider
        sx={{
          borderColor: "#EAEAEA",
        }}
      />
      <Stack direction="row" gap={1} p={2}>
        {transaction?.fees &&
          (isSmall ? (
            <Typography
              fontFamily="Ubuntu, sans-serif"
              fontSize="11px"
              lineHeight={1.43}
              fontWeight={700}
              color="#A09E9E"
              mb="auto"
            >
              FEE:&nbsp;{transaction?.fees}&nbsp;BTG
            </Typography>
          ) : (
            <Stack bgcolor="#EBEBEB" borderRadius={1} py={1} px={3}>
              <Typography
                fontFamily="Ubuntu, sans-serif"
                fontSize="13px"
                lineHeight={1.43}
                fontWeight={100}
              >
                FEE:&nbsp;{transaction?.fees}&nbsp;BTG
              </Typography>
            </Stack>
          ))}
        {isSmall ? (
          <Stack ml="auto">
            {transaction?.confirmations === 0 ? (
              <Typography
                fontFamily="Ubuntu, sans-serif"
                fontSize="11px"
                lineHeight={1.43}
                color="dimgray"
                fontWeight={700}
              >
                UNCONFIRMED TRANSACTION!
              </Typography>
            ) : (
              <Typography
                fontFamily="Ubuntu, sans-serif"
                fontSize="11px"
                lineHeight={1.43}
                color="dimgray"
                fontWeight={700}
              >
                {transaction?.confirmations ?? 0}&nbsp;CONFIRMATIONS
              </Typography>
            )}
            <Stack bgcolor="#EDAB36" py={0} px={0}>
              <Typography
                fontFamily="Ubuntu, sans-serif"
                fontSize="11px"
                lineHeight={1.43}
                color="#A09E9E"
                fontWeight={100}
              >
                {transaction?.valueOut ?? 0}&nbsp;BTG
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <>
            {transaction?.confirmations === 0 ? (
              <Stack bgcolor="#B5001A" ml="auto" borderRadius={1} py={1} px={3}>
                <Typography
                  fontFamily="Ubuntu, sans-serif"
                  fontSize="13px"
                  lineHeight={1.43}
                  color="#FFF"
                  fontWeight={100}
                >
                  UNCONFIRMED TRANSACTION!
                </Typography>
              </Stack>
            ) : (
              <Stack bgcolor="#696969" ml="auto" borderRadius={1} py={1} px={3}>
                <Typography
                  fontFamily="Ubuntu, sans-serif"
                  fontSize="13px"
                  lineHeight={1.43}
                  color="#FFF"
                  fontWeight={100}
                >
                  {transaction?.confirmations || 0}&nbsp;CONFIRMATIONS
                </Typography>
              </Stack>
            )}
            <Stack bgcolor="#EDAB36" borderRadius={1} py={1} px={3}>
              <Typography
                fontFamily="Ubuntu, sans-serif"
                fontSize="13px"
                lineHeight={1.43}
                color="#FFF"
                fontWeight={100}
              >
                {transaction?.valueOut || 0}&nbsp;BTG
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default TransactionDetail;
