import {
  AppBar,
  AppBarProps,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";

interface Props extends AppBarProps { }

const Footer = ({ sx }: Props) => {
  const isSmall = useResponsive("down", "md");

  return (
    <AppBar
      position="fixed"
      sx={{
        minHeight: isSmall ? "51px" : "unset",
        bgcolor: "#132c47",
        ...sx,
      }}
    >
      <Toolbar sx={{ width: "100%", maxWidth: "lg", margin: "auto", py: 0 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography fontSize={isSmall ? 10 : 12}>
            All rights reserved Bitcoin Gold
          </Typography>
          <Typography fontSize={isSmall ? 10 : 12} mr={isSmall ? 4 : "unset"}>
            Broadcast Transaction
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar >
  );
};

export default Footer;
