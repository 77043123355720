import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { Suspense } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";

function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <Box sx={{ flexGrow: 1 }}>
          <Header sx={{ top: 0 }} />
          <Container maxWidth="lg">
            <Stack px={0.5} py={9}>
              <Router />
            </Stack>
          </Container>
          <Footer sx={{ bottom: 0, top: "auto" }} />
        </Box>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
