import {
  Snackbar,
  SnackbarOrigin,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "src/api";

interface SnackState extends SnackbarOrigin {
  open: boolean;
}

const SearchField = ({ children, sx, ...other }: TextFieldProps) => {
  const navigate = useNavigate();

  const [snack, setSnack] = useState<SnackState>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snack;

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      processSearch(e.target.value);
    }
  };

  const processSearch = (search: string) => {
    (async () => {
      console.log("processSearch", search);
      const len = search.length;
      if (len > 26 && len < 36) {
        try {
          await API.fetchAddressDataOff(search);
          navigate(`/insight/address/${search}`);
          return;
        } catch (error) {
          console.log("not address search");
        }
        try {
          await API.fetchAddressData(search);
          navigate(`/insight/address/${search}`);
          return;
        } catch (error) {
          console.log("not address search");
        }
      }
      if (len == 64) {
        try {
          await API.fetchTransactionOff(search);
          navigate(`/insight/tx/${search}`);
          return;
        } catch (error) {
          console.log("not tx search");
        }
        try {
          await API.fetchTransaction(search);
          navigate(`/insight/tx/${search}`);
          return;
        } catch (error) {
          console.log("not tx search");
        }

        try {
          await API.fetchBlock(search);
          navigate(`/insight/block/${search}`);
          return;
        } catch (error) {
          console.log("not block search");
        }
      }

      try {
        const data = await API.fetchBlockHashByBlockHeight(search);
        navigate(`/insight/block/${data}`);
        return;
      } catch (error) {
        console.log("not address search");
      }
      setSnack({ vertical: "top", horizontal: "center", open: true });
    })();
  };

  const closeSnack = () => {
    setSnack({ vertical: "top", horizontal: "center", open: false });
  };

  return (
    <>
      <TextField
        {...other}
        onKeyDown={handleSearch}
        placeholder="Search for block, transaction or address"
        sx={{
          borderRadius: 1,
          color: "white",
          p: 0,
          "& input": {
            px: "12px",
            py: "6px",
            fontSize: "0.875rem",
            fontFamily: "Ubuntu, sans-serif",
            fontStyle: "italic",
          },
          "& fieldset": {
            display: "none",
          },
          ...sx,
        }}
      >
        {children}
      </TextField>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={closeSnack}
        autoHideDuration={2000}
        message="No matching records found!"
        key={vertical + horizontal}
      />
    </>
  );
};

export default SearchField;
