import { lazy } from "react";
import { Outlet, useRoutes, Navigate } from "react-router-dom";
import Address from "src/pages/Address";
import Transaction from "src/pages/Transaction";

const Home = lazy(() => import("src/pages/Home"));
const Blocks = lazy(() => import("src/pages/Blocks"));
const Status = lazy(() => import("src/pages/Status"));
const Block = lazy(() => import("src/pages/Block"));

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/insight" replace />
    },
    {
      path: "/insight",
      element: <Home />
    },
    {
      path: "/insight/blocks",
      element: <Blocks />
    },
    {
      path: "/insight/status",
      element: <Status />
    },
    {
      path: "/insight/block/:blockId",
      element: <Block />
    },
    {
      path: "/insight/tx/:txId",
      element: <Transaction />
    },
    {
      path: "/insight/address/:address",
      element: <Address />
    },
    {
      path: "*",
      element: <Home />
    }
  ])
}

export default Router;