const chars = "0123456789abcdef";
export const generateHex = (length: number) => {
  let ret = "";
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * 15);
    ret += chars[index];
  }
  return ret;
  //3045022100fb55fd40f04b81cfd13c86ae93b48f7a895c496b5d2b716f3452170e5daa74530220115de44717297ce0dfab5ed6bc13158a9c51b68268e9760e008bf61c3c452948
};
