import { Divider, Stack, Typography, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TransactionDetail from "src/components/transaction-detail";
import * as API from "src/api";
import { format } from "date-fns";
import CustomLink from "src/components/custom-link";
import { useResponsive } from "src/hooks/use-responsive";
import { Helmet } from 'react-helmet-async';

const Transaction = () => {
  const { txId } = useParams();
  const [transaction, setTransaction] = useState<any>();

  const navigate = useNavigate();
  const isSmall = useResponsive("down", "sm");

  const [isOff, setIsOff] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await API.fetchTransactionOff(txId);
        setTransaction(data);
        setIsOff(true);
      } catch (error) {
        console.log("fetchTransactionOff : error", error);
        try {
          const data = await API.fetchTransaction(txId);
          setTransaction(data);
        } catch (error) {
          console.log("fetchTransaction: error", error);
        }
      }
    })();
  }, [txId]);

  return (
    <Stack color="#373D42">
      <Helmet>
        <title>Bitcoingold Scanner | Transaction Page</title>
        <meta name="keywords" content="bitcoin gold, bitcoin gold scan, bitcoin gold search, bitcoin gold transaction" />
        <meta name="description" content="It's bitcoin gold individual transaction infomation page" />
      </Helmet>
      <Typography
        component="h1"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "24px" : "36px"}
        fontWeight={500}
        lineHeight={1.1}
        mt={2}
      >
        Transaction
      </Typography>
      <Stack
        bgcolor="#F4F4F4"
        p={1}
        mt={1}
        borderRadius={1.5}
        border="1px solid #EEEEEE"
      >
        <Stack direction="row">
          <Typography
            fontFamily="Ubuntu, sans-serif"
            component="span"
            fontWeight={700}
            fontSize="14px"
            lineHeight={1.43}
          >
            Transaction
          </Typography>
          <Typography
            fontFamily="Ubuntu, sans-serif"
            component="span"
            color="#999"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            fontSize="14px"
            lineHeight={1.43}
            ml={0.5}
          >
            {txId}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        component="h2"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "20px" : "30px"}
        color="#373D42"
        fontWeight={500}
        lineHeight={1.1}
        mt={3}
      >
        Summary
      </Typography>
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      <Stack
        color="#373D42"
        direction="row"
        justifyContent="space-between"
        ml={1}
        mt={1}
      >
        <Typography
          fontFamily="Ubuntu, sans-serif"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
          fontWeight={700}
        >
          Size
        </Typography>
        <Typography
          fontFamily="Ubuntu, sans-serif"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
          color="#999"
        >
          {transaction?.size}&nbsp;(bytes)
        </Typography>
      </Stack>
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      <Stack
        color="#373D42"
        direction="row"
        justifyContent="space-between"
        ml={1}
        mt={1}
      >
        <Typography
          fontFamily="Ubuntu, sans-serif"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
          fontWeight={700}
        >
          Fee Rate
        </Typography>
        <Typography
          fontFamily="Ubuntu, sans-serif"
          color="#999"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
        >
          {/*(transaction?.fees * 1000) / transaction?.size*/}
          0.0024 &nbsp;BTG&nbsp;per&nbsp;kB
        </Typography>
      </Stack>
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      <Stack
        color="#373D42"
        direction="row"
        justifyContent="space-between"
        ml={1}
        mt={1}
      >
        <Typography
          fontFamily="Ubuntu, sans-serif"
          fontWeight={700}
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
        >
          Received Time
        </Typography>
        <Typography
          fontFamily="Ubuntu, sans-serif"
          color="#999"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
        >
          {format(
            isOff
              ? transaction.ts * 1000 || 0
              : (transaction?.time || 0) * 1000,
            "MMM d, yyyy h:mm:ss a"
          )}
        </Typography>
      </Stack>
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      <Stack
        color="#373D42"
        direction="row"
        justifyContent="space-between"
        ml={1}
        mt={1}
      >
        <Typography
          fontFamily="Ubuntu, sans-serif"
          fontWeight={700}
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
        >
          Mined Time
        </Typography>
        <Typography
          fontFamily="Ubuntu, sans-serif"
          color="#999"
          lineHeight={1.43}
          fontSize={isSmall ? "11px" : "14px"}
        >
          {format(
            isOff
              ? transaction.ts * 1000 || 0
              : (transaction?.time || 0) * 1000,
            "MMM d, yyyy h:mm:ss a"
          )}
        </Typography>
      </Stack>
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      {!isOff && (
        <Stack
          color="#373D42"
          direction="row"
          justifyContent="space-between"
          ml={1}
          mt={1}
        >
          <Typography
            fontFamily="Ubuntu, sans-serif"
            fontWeight={700}
            lineHeight={1.43}
            fontSize={isSmall ? "11px" : "14px"}
            flex="1 1 0px"
          >
            Included in Block
          </Typography>
          {transaction?.blockhash ? (
            <CustomLink
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flex="1 1 0px"
              textAlign="right"
              onClick={() =>
                navigate(`/insight/block/${transaction?.blockhash}`)
              }
            >
              {transaction?.blockhash}
            </CustomLink>
          ) : (
            <Typography
              fontFamily="Ubuntu, sans-serif"
              fontWeight={700}
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              textAlign="right"
              flex="1 1 0px"
            >
              Unconfirmed
            </Typography>
          )}
        </Stack>
      )}
      <Divider
        sx={{
          marginTop: 1,
          borderColor: "#EEEEEE",
        }}
      />
      <Typography
        component="h2"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "20px" : "30px"}
        color="#373D42"
        fontWeight={500}
        lineHeight={1.1}
        mt={4}
      >
        Details
      </Typography>
      <TransactionDetail data={transaction} />
    </Stack>
  );
};

export default Transaction;
