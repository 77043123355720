import axios from "axios";
import {ENVIRONMENT} from "src/config"

const BTG_API_ROOT = "https://explorer.bitcoingold.org/insight-api"
const BTG_API_ROOT_OFF = ENVIRONMENT === "development"  ? "http://localhost:5000/explorer": "https://www.coinsvault.io/explorer";


export const fetchRecentBlocks = async () => {
    const url = `${BTG_API_ROOT}/blocks?limit=5`;
    const { data } = await axios.get(url);
    return data.blocks;
}

export const fetchBlock = async (blockId: any) => {
    const url = `${BTG_API_ROOT}/block/${blockId}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchTransaction = async (txId: any) => {
    const url = `${BTG_API_ROOT}/tx/${txId}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchTransactionOff = async (txId: any) => {
    const url = `${BTG_API_ROOT_OFF}/tx/${txId}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchBlocks = async () => {
    const url = `${BTG_API_ROOT}/blocks`;
    const { data } = await axios.get(url);
    return data.blocks;
}

export const fetchBlocksByDate = async (date: any) => {
    const url = `${BTG_API_ROOT}/blocks?blockDate=${date}`;
    const { data } = await axios.get(url);
    return data.blocks;
}

export const fetchSyncData = async () => {
    const url = `${BTG_API_ROOT}/sync`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchNodeInfo = async () => {
    const url = `${BTG_API_ROOT}/status?q=getInfo`;
    const { data } = await axios.get(url);
    return data.info;
}

export const fetchLastBlockHash = async () => {
    const url = `${BTG_API_ROOT}/status?q=getLastBlockHash`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchAddressData = async (address: any) => {
    const url = `${BTG_API_ROOT}/addr/${address}/?noTxList=1`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchAddressDataOff = async (address: any) => {
    const url = `${BTG_API_ROOT_OFF}/address/${address}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchTransactionsByAddress = async (address: any, pageNum: number) => {
    const url = `${BTG_API_ROOT}/txs?address=${address}&pageNum=${pageNum}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchTransactionsByAddressOff = async (address: any, pageNum: number) => {
    const url = `${BTG_API_ROOT_OFF}/txs/${address}?pageNum=${pageNum}`;
    const { data } = await axios.get(url);
    return data;
}

export const fetchBlockHashByBlockHeight = async (height: any) => {
    const url = `${BTG_API_ROOT}/block-index/${height}`;
    const { data } = await axios.get(url);
    return data.blockHash;
}