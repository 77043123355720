import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "src/api";
import TransactionDetail from "src/components/transaction-detail";
import QRCode from "qrcode.react";
import { useResponsive } from "src/hooks/use-responsive";
import { Helmet } from "react-helmet-async";
import useStateRef from "react-usestateref";

const Address = () => {
  const { address } = useParams();
  const [addressInfo, setAddressInfo] = useState<any>(null);
  const [transactions, setTransactions] = useState<any>([]);
  const [isOff, setIsOff] = useState<boolean | null>(null);
  const [pageNum, setPageNum, pageNumRef] = useStateRef<number>(0);
  const pageRef = useRef<any>();
  const [loading, setLoading, loadingRef] = useStateRef(false);
  const [needLoad, setNeedLoad, needLoadRef] = useStateRef(true);

  useEffect(() => {
    setTransactions([]);
    setIsOff(null);
    setPageNum(0);
    setLoading(false);
    setNeedLoad(true);
  }, [address]);

  useEffect(() => {
    (async () => {
      try {
        // get off chain info
        const data = await API.fetchAddressDataOff(address);
        const address_info = {
          ...data,
          addrStr: address,
        };
        setAddressInfo(address_info);
        console.log("address data", data);
        setIsOff(true);
      } catch (error) {
        console.log("fetchAddressDataOff: error", error);
        // get on chain info
        try {
          const data = await API.fetchAddressData(address);
          setAddressInfo(data);
          setIsOff(false);
        } catch (error) {
          console.log("fetchAddressData: error", error);
        }
      }
    })();
  }, [address, pageNum]);

  useEffect(() => {
    console.log("load transactions hook", isOff);
    (async () => {
      if (isOff == null) return;
      try {
        // get off chain info
        setLoading(true);
        let data = [];
        if (isOff) {
          data = await API.fetchTransactionsByAddressOff(address, pageNum);
        } else {
          const result = await API.fetchTransactionsByAddress(address, pageNum);
          data = result.txs;
        }
        if (data.length) {
          setTransactions([...transactions, ...data]);
        }
        if (data.length < 10) setNeedLoad(false);
        setLoading(false);
      } catch (error) {
        console.log("fetchTransaction: error", error, isOff);
      }
    })();
  }, [address, pageNum, isOff]);

  const isSmall = useResponsive("down", "sm");

  const onScroll = () => {
    if (needLoadRef.current && pageRef.current) {
      const bottom = pageRef.current.getBoundingClientRect().bottom;
      const inner_height = window.innerHeight;
      if (bottom < inner_height && !loadingRef.current) {
        setPageNum(pageNumRef.current + 1);
        setLoading(true);
      }
      console.log("scroll", inner_height, bottom, pageNumRef.current, needLoad);
    }
  };

  useEffect(() => {
    if (pageRef.current) {
      document.addEventListener("scroll", onScroll);
    }
    return () => {
      if (pageRef.current) {
        document.removeEventListener("scroll", onScroll);
      }
    };
  }, []);

  return (
    <Stack color="#373D42" ref={pageRef}>
      <Helmet>
        <title>Bitcoingold Scanner | Address Page</title>
        <meta
          name="keywords"
          content="bitcoin gold, bitcoin gold scan, bitcoin gold search, bitcoin gold address"
        />
        <meta name="description" content="It's bitcoin gold address page" />
      </Helmet>
      <Typography
        component="h1"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "24px" : "36px"}
        fontWeight={500}
        lineHeight={1.1}
        mt={2}
      >
        Address
        <Typography
          ml={1}
          component="span"
          fontSize={isSmall ? "16px" : "23.4px"}
          color="#999"
          lineHeight="1"
          fontWeight={400}
        >
          {addressInfo?.balance}&nbsp;BTG
        </Typography>
      </Typography>
      <Stack
        bgcolor="#F4F4F4"
        p={1}
        mt={1}
        borderRadius={1.5}
        border="1px solid #EEEEEE"
      >
        <Stack direction="row">
          <Typography
            fontFamily="Ubuntu, sans-serif"
            component="span"
            fontWeight={700}
            fontSize={isSmall ? "11px" : "14px"}
            lineHeight={1.43}
          >
            Address
          </Typography>
          <Typography
            fontFamily="Ubuntu, sans-serif"
            component="span"
            color="#999"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            fontSize={isSmall ? "11px" : "14px"}
            lineHeight={1.43}
            ml={0.5}
          >
            {address}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        component="h2"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "20px" : "30px"}
        color="#373D42"
        fontWeight={500}
        lineHeight={1.1}
        mt={3}
      >
        Summary
        <Typography
          ml={1}
          component="span"
          fontSize={isSmall ? "14px" : "19.5px"}
          color="#999"
          lineHeight="1"
          fontWeight={400}
        >
          confirmed
        </Typography>
      </Typography>
      <Stack direction={isSmall ? "column" : "row"} gap={isSmall ? 0 : 2}>
        <Stack flex={isSmall ? "unset" : "5 1 0px"}>
          <Divider
            sx={{
              marginTop: 1,
              borderColor: "#EEEEEE",
            }}
          />
          <Stack
            color="#373D42"
            direction="row"
            justifyContent="space-between"
            ml={1}
            mt={1}
          >
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              fontWeight={700}
            >
              Total Received
            </Typography>
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              color="#999"
            >
              {addressInfo?.totalReceived}&nbsp;(BTG)
            </Typography>
          </Stack>
          <Divider
            sx={{
              marginTop: 1,
              borderColor: "#EEEEEE",
            }}
          />
          <Stack
            color="#373D42"
            direction="row"
            justifyContent="space-between"
            ml={1}
            mt={1}
          >
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              fontWeight={700}
            >
              Total Sent
            </Typography>
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              color="#999"
            >
              {addressInfo?.totalSent}&nbsp;(BTG)
            </Typography>
          </Stack>
          <Divider
            sx={{
              marginTop: 1,
              borderColor: "#EEEEEE",
            }}
          />
          <Stack
            color="#373D42"
            direction="row"
            justifyContent="space-between"
            ml={1}
            mt={1}
          >
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              fontWeight={700}
            >
              Final Balance
            </Typography>
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              color="#999"
            >
              {addressInfo?.balance}&nbsp;(BTG)
            </Typography>
          </Stack>
          <Divider
            sx={{
              marginTop: 1,
              borderColor: "#EEEEEE",
            }}
          />
          <Stack
            color="#373D42"
            direction="row"
            justifyContent="space-between"
            ml={1}
            mt={1}
          >
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              fontWeight={700}
            >
              No. Transactions
            </Typography>
            <Typography
              fontFamily="Ubuntu, sans-serif"
              lineHeight={1.43}
              fontSize={isSmall ? "11px" : "14px"}
              color="#999"
            >
              {addressInfo?.txApperances}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          mt={isSmall ? 4 : "unset"}
          minWidth={160}
          minHeight={160}
          flex="1 1 0px"
          justifyContent="center"
          alignItems="center"
        >
          {address && (
            <QRCode
              size={160}
              value={`https://www.bitcoingoldscanner.com/insight/address/${address}`}
            />
          )}
        </Stack>
      </Stack>
      <Typography
        component="h2"
        fontFamily="Ubuntu, sans-serif"
        fontSize={isSmall ? "20px" : "30px"}
        color="#373D42"
        fontWeight={500}
        lineHeight={1.1}
        mt={4}
      >
        Transactions
      </Typography>
      {transactions?.map((v: any, i: number) => (
        <TransactionDetail data={v} key={i} sx={{}} />
      ))}
      {loading && (
        <Skeleton variant="rectangular" sx={{ mt: 3 }} height={240} />
      )}
    </Stack>
  );
};

export default Address;
