import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  ButtonProps,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Stack,
  TextField,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "src/hooks/use-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import SearchField from "../search-field";
import { useState } from "react";

interface Props extends AppBarProps { }

const HeaderButton = ({ children, sx, ...other }: ButtonProps) => (
  <Button
    {...other}
    sx={{
      bgcolor: "transparent",
      textTransform: "none",
      border: "none",
      borderRadius: 0,
      fontWeight: 500,
      boxShadow: "none",
      "&:hover": {
        bgcolor: "white",
        color: "#132c47",
        boxShadow: "none",
      },
      ...sx,
    }}
  >
    {children}
  </Button>
);

const Header = ({ sx }: Props) => {
  const navigate = useNavigate();
  const isSmall = useResponsive("down", "md");
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      {isSmall ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              minHeight: 59,
              bgcolor: "#132c47",
              py: 0,
              ...sx,
            }}
          >
            <Toolbar sx={{ width: "100%", maxWidth: "lg", margin: "auto", py: 0 }}>
              <Stack direction="row" width="100%" alignItems="center">
                <Box component="img" src="/logo.webp"
                  onClick={() => navigate("/insight")}
                  sx={{
                    height: 48,
                    p:1,
                    "&:hover": {
                      cursor: "pointer",
                    }
                  }} />
                <Stack
                  onClick={() => { setOpenMenu(!openMenu) }}
                  sx={{
                    px: 0,
                    width: "40px",
                    height: "32px",
                    ml: "auto",
                    mr: 4,
                    border: "1px solid white",
                    borderRadius: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#373D42",
                  }}>
                  <MenuIcon sx={{
                    color: "#FFF",
                    fontSize: 28,
                  }} />
                </Stack>
              </Stack>
            </Toolbar>
          </AppBar>
          {openMenu &&
            <Stack
              bgcolor="#132c47"
              position="fixed"
              minHeight="123.5px"
              py="auto"
              top="59px"
              borderTop="1px solid white"
              width="100%"
              gap={0.5}
              zIndex={1}
            >
              <Stack justifyContent="center" height="50px" color="white" px={2} mt={1}
                onClick={() => {
                  navigate(`/insight/blocks/`);
                  setOpenMenu(false);
                }}
                sx={{
                  "&:hover": {
                    bgcolor: "white",
                    color: "#373D42",
                  }
                }}>
                <Typography
                  fontFamily="Ubuntu, sans-serif"
                  fontSize="11px"
                >
                  Blocks
                </Typography>
              </Stack>
              <Stack justifyContent="center" height="50px" color="white" px={2}
                onClick={() => {
                  navigate(`/insight/status/`);
                  setOpenMenu(false);
                }}
                sx={{
                  "&:hover": {
                    bgcolor: "white",
                    color: "#373D42",
                  }
                }}>
                <Typography
                  fontFamily="Ubuntu, sans-serif"
                  fontSize="11px"
                >
                  Status
                </Typography>
              </Stack>
            </Stack>}
        </>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            minHeight: 64,
            bgcolor: "#132c47",
            py: 0,
            ...sx,
          }}
        >
          <Toolbar sx={{ width: "100%", maxWidth: "lg", margin: "auto", py: 0 }}>
            <Stack direction="row" width="100%" spacing={1} alignItems="center">
              <Box component="img" src="/logo.webp"
                onClick={() => navigate("/insight")}
                sx={{
                  height: 48,
                  p:1,
                  "&:hover": {
                    cursor: "pointer",
                  }
                }} />
              <HeaderButton
                variant="contained"
                sx={{ height: 64 }}
                disableRipple
                onClick={() => navigate("/insight/blocks")}
              >
                Blocks
              </HeaderButton>
              <HeaderButton
                variant="contained"
                sx={{ height: 64 }}
                disableRipple
                onClick={() => navigate("/insight/status")}
              >
                Status
              </HeaderButton>
              <Stack justifyContent="center">
                <SearchField
                  label=""
                  variant="outlined"
                  sx={{ height: 34, width: 300, bgcolor: "#718091", }}
                />
              </Stack>
              <Stack flexGrow={1} />
              <Stack direction="row" alignItems="center" spacing="4px">
                <CheckIcon sx={{ fontSize: 12, stroke: "white", strokeWidth: 4 }} />
                <Typography fontSize={12} fontWeight={700}>
                  · Conn
                </Typography>
                <Typography fontSize={12}>8</Typography>
                <Typography fontSize={12} fontWeight={700}>
                  · Height
                </Typography>
                <Typography fontSize={12}>837164</Typography>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Header;
