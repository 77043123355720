import { Link, LinkProps } from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";

const CustomLink = ({ children, ...other }: LinkProps) => {
    const isSmall = useResponsive("down", "sm");

    return (
        <Link
            {...other}
            fontFamily="Ubuntu, sans-serif"
            component="a"
            fontSize={isSmall ? "11px" : "14px"}
            lineHeight={1.43}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer"
                },
            }}
        >
            {children}
        </Link>
    );
}
export default CustomLink;